





















































::v-deep {
  .v-data-table {
    tr {
      &:hover {
        cursor: pointer;
      }
    }

    &--fixed-header {
      height: 100%;

      .v-data-table__wrapper {
        max-height: 100%;
      }
    }
  }
}

