











































































































































































































@import './src/styles/variables';

* ::v-deep .vue-grid-item {
  &.vue-grid-placeholder {
    background: $primary-brand-color;
    opacity: 0.13;
  }
}

.inline-menu {
  &__item {
    text-decoration: none;
    color: rgba($link-color, 0.55);
    padding: 0 $list-item-action-icon-margin;
    &:hover {
      color: rgba($link-color, 0.90);
    }
    &--current {
      color: $link-color;
    }
  }
}
.center {
  justify-content: center;
}

.widget-loader {
  position: fixed;
  top: 33%;
  left: 50%;
}

.data-wrapper {
  &.loading {
    opacity: 0.5;
  }
}
