
















































































































































































































































































































table {
  tr {
    .actions {
      .v-icon {
        visibility: hidden;
      }
    }
    &:hover {
      .actions {
        .v-icon {
          visibility: visible;
        }
      }
    }
  }
}
.v-select {
    width: 1%;
    justify-content: center;
    margin-top: 25px;
}
