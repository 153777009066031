




































































































































































































































table {
  tr {
    .actions {
      .v-icon {
        visibility: hidden;
      }
    }
    &:hover {
      .actions {
        .v-icon {
          visibility: visible;
        }
      }
    }
  }
}
