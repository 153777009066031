




































@import './src/styles/variables';

.v-app-bar {
  background: $sheet-bg;
}
.v-toolbar {
  &__title {
    margin-right: 20px;
    font-size: 1.15rem;
    line-height: 1.35;
  }
}
.v-btn {
  margin-left: 5px;
}
