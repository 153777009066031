














































































































































































































































































































































































































































































































.chart-wrapper {
  height: 260px;
  padding: 16px;
}

.wrapper {
  padding: 16px;
}

.map-wrapper {
  //  height: calc(110% - 1px);
  height: 100%;
  padding: 16px;
}

$headerHeight: 48px;
$headerBorderHeight: 1px;

.widget {
  &__header {
    height: $headerHeight;

    &:after {
      content: "";
      position: absolute;
      bottom: -1px;
      left: 16px;
      right: 16px;
      background-color: rgba(0, 42, 76, 0.1);
      height: 1px;
      transition: left 0.3s ease, right 0.3s ease;
    }
  }

  &__actions {
    &:before {
      display: none;
    }
  }

  &__subtitle {
    font-size: 13px;
    line-height: 30px;
    font-weight: bold;
  }

  &__content {
    padding: 16px;

    &--scrollable {
      position: relative;
      height: calc(100% - #{$headerHeight} - #{$headerBorderHeight});
      margin: $headerBorderHeight auto auto auto;
    }
  }
}

.widget-loader {
  position: fixed;
  top: 33%;
  left: 50%;
}

.data-wrapper {
  &.loading {
    opacity: 0.5;
  }
}

