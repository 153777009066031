












.app-style {
  height: 100vh;
}

.container {
  height: 100%;
  .row {
    height: 100%;
    .col {
      height: 100%;
    }
  }
}

* {
  ::v-deep {
    .v-data-table {
      width: 100%;
      ::v-deep {
        td, th {
          &:first-of-type {
            padding-left: 20px;
          }
        }
      }
      &--fixed-header {
        height: 100%;
        .v-data-table__wrapper {
          max-height: 100%;
        }
      }
    }
  }
}
