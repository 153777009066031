@import '~vuetify/src/styles/settings/variables';

// Typography
$primary-font-family: 'Lato', Helvetica, Arial, sans-serif;
$secondary-font-family: 'Fira Sans', Arial, sans-serif;

// Color system
$primary: #454545;
$body-bg: #FAFBFC;
//$body-bg: #F6F5F6;
$sheet-bg: #FFFFFF;
$primary-brand-color: #005164;

$text-color: $primary;
$link-color: $primary;

// Sizes
$columns-gap: 1rem;

// Overrides Vuetify theme
$material-light: (
  'background': $body-bg,
);

$body-font-family: $primary-font-family;
$heading-font-family: $secondary-font-family;
$border-radius-root: 2px;

// elevation
$shadow-key-umbra-opacity: rgba(0, 0, 0, 0.16) !default;
$shadow-key-penumbra-opacity: rgba(0, 0, 0, 0.1) !default;
$shadow-key-ambient-opacity: rgba(0, 0, 0, 0.08) !default;

// v-list
$list-item-action-icon-margin: 12px;
