





















































::v-deep {
  .v-data-table {
    tr {
      &:hover {
        cursor: pointer;
      }
    }
    td.text-right{
      padding-right: 30px;
    }

    &--fixed-header {
      height: 100%;

      .v-data-table__wrapper {
        max-height: 100%;
      }
    }
  }
}

