






















































































































































































































































@import './src/styles/variables';

.v-toolbar {
  &__title {
    margin-right: 10px;
    font-size: 1.15rem;
    line-height: 1.35;
  }
}

::v-deep .v-toolbar__extension {
  border-top: thin solid rgba(0, 0, 0, 0.12);
}

.table {
  margin-right: 10px;
}

.reports-filter {
  display: flex;
  color: lighten($text-color, 20%);

  &__label {
    margin-right: 10px;
  }

  &__option {
    margin-top: 0;
  }

  &__button {
    &--active {
      color: green;

      span {
        color: black;
      }
    }
  }
}
