

































































































@import './src/styles/variables';

.reports-filter {
  display: flex;
  color: lighten($text-color, 20%);

  &__label {
    margin-right: 10px;
  }

  &__option {
    margin-top: 0;
  }

  &__button {
    &--active {
      color: green;

      span {
        color: black;
      }
    }
  }
}
