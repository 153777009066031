






























































































































































































 table {
   border-collapse: collapse;
   text-align: center;
   font-family: 'Roboto', sans-serif;
}
table thead tr th {
   font-size: 10px;
   color: gray;
   border-bottom: 1px solid gray;
}
table tbody tr {
  page-break-inside: avoid;
}
 table tbody tr td {
   font-size: 10px;
   height: 30px;
   border-bottom: 1px solid lightgray;
   page-break-inside: avoid;
}
#type {
    width: 100px;
}
 #locationName {
    width: 100px;
}
 .days {
    width: 30px;
}
